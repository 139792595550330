.btn-banner {
    margin-top: 20px;
    padding: 10px 25px;
    font-family: "Gils Sans", "Gill Sans MT", Calibri, 'Trebuchet MS', sans-serif;
    transition: 0.5s;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    color: rgb(14, 14, 134);
}

.btn-banner:hover {
    transform: translateY(-7px);
    background-color: rgb(14, 14, 134);
    box-shadow: 0px 15px 10px rgba(0, 42, 300, 0.4);
    color: #fff;
    border: none;
}

/* Button About Component */

.btn-about-c {
    margin-top: 20px;
    padding: 10px 25px;
    font-family: 'Gils Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
    transition: 0.5s ;
}

.btn-about-c:hover {
    transform: scale(1.1);
    border: 1px solid;
    box-shadow: 3px 3px #fff;
}