.dlab-bnr-inr-entry {
  vertical-align: bottom;
  margin: 1em 0px 0px 0px !important;
  background-color: #1a1b1f;
  width: 100%;
  height: 250px;
  /* background-image: url(../../../public/images/banner/BackgroundPagetitle.WebP); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dlab-bnr-inr-entry h1 {
  font-weight: bolder;
}
.dlab-bnr-inr-entry.align-m {
  vertical-align: middle;
}

.list-inline > li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .ele-bnr .dlab-bnr-inr-entry {
    text-align: center;
  }
  .dlab-bnr-inr-entry {
    vertical-align: middle;
  }
}
