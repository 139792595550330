.feature-content p {
  text-align: justify !important;
}

.feature button {
  width: 100%;
}
.feature-content h2 {
  line-height: normal;
  font-weight: bold;
}

@media only screen and (max-width: 426px) {
  .feature-content h2 {
    margin-top: 1rem;
    font-size: 16px;
  }
}
