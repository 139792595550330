.title {
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 50px;

}

.c-doc {
    margin: 30px;
    padding: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.c-doc h1 {
    text-align: center;
    font-size: 37px;
    font-weight: 700;
    text-shadow: 3px 3px 5px rgb(60, 135, 247);
}

.c-doc .description {
    padding: 5px;
}

.c-doc .description h3 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 23px;
    font-weight: 800;
    margin-top: 15px;
    margin-bottom: 15px;
    color: rgb(17, 0, 112);
}

.c-doc .description h4 {
    font-size: 20px !important;
    font-weight: 500;
    margin: 20px 20px 20px 25px;
}

.c-doc .description p {
    margin-bottom: 40px;
}

.c-doc .description ul {
    margin-top: 30px;
    margin-bottom: 30px;
}




